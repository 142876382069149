<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="content">
        <a-card title="年卡充值">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-row>
              <a-col :span="12">
                <a-row>
                  <a-col>
                    <a-form-model-item label="会员卡号" prop="mobile">
                      <a-input-search
                        placeholder="请输入会员卡号/手机号"
                        allowClear
                        v-model.trim="form.mobile"
                        :disabled="isDetail"
                        @change="handleSearch"
                        @pressEnter="handleSearch"
                      >
                      </a-input-search>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="备注" prop="remarks">
                      <a-input
                        v-model="form.remarks"
                        :auto-size="{ minRows: 4, maxRows: 6 }"
                        allowClear
                        :maxLength="100"
                        type="textarea"
                        :disabled="isDetail"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row v-if="currentMember.memberId">
                  <a-col style="margin-left: 190px">
                    <a-row>
                      <button v-on:click="startCamera">打开摄像头</button>
                      <button v-on:click="takePicture">拍照并上传</button>
                    </a-row>
                    <video ref="videoElement" style="width: 320px;height: 240px"></video>
                    <canvas ref="canvasElement" width="320" height="240"></canvas>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <ul class="goods__list">
                      <section v-for="item in feeList" :key="item.yearCardId">
                        <li
                          class="goods__item"
                        >
                          <div
                            class="couponCardItem"
                            @click="handleChooseFeeRule(item)"
                          >
                            <div class="yuan"></div>
                            <div class="left">
                              <div class="l-inner">
                                <div class="l1">
                                  ￥<span style="font-weight: bold; line-height: 1">{{
                                    item.price
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="right">
                              <div class="Title_name">{{
                                  item.yearCardName
                                }}</div>
                            </div>
                            <div class="checked-ouline" v-if="!!item.checked">
                              <a-icon type="check-circle" />
                            </div>
                          </div>
                        </li>
                      </section>
                    </ul>
                  </a-col>
                </a-row>
              </a-col>
              <a-col span="10" push="1" v-if="!paramsId">
                <a-descriptions title="会员信息" :column="1">
                  <a-descriptions-item label="姓名">{{
                    currentMember.realName || "-"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="手机号">{{
                    currentMember.mobile || "-"
                  }}</a-descriptions-item>
                </a-descriptions>
                <a-row>
                  <img v-if="previewImageUrl" :src="previewImageUrl">
                </a-row>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>

    <!-- 收款弹出窗 开始 -->
    <payment-modal
      v-model="payVisible"
      :data="form"
      @on-ok="handlePayOk"
      @on-cancel="handleCancel"
    ></payment-modal>
    <!-- 收款弹出窗 结束 -->
  </div>
</template>

<script>
import axios from 'axios';
import { getByCodeOrMobile, memberUpdateTimePicture } from '@/api/member';
import FormFrame from '@/components/frame/FormFrame.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import formMixin from '@/mixin/formMixin';
import { deepClone } from '@/libs/utils';
import { yearList, yearRechargeSubmit } from '@/api/year';

export default {
  name: 'vipRechargeForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    PaymentModal,
  },
  props: {},
  data() {
    return {
      videoStream: null,
      previewImageUrl: null,
      canvasWidth: 320,
      canvasHeight: 240,
      feeList: [],
      selectFeeList: [],
      currentMember: {},
      payVisible: false,
      paymentMap: new Map([
        [1, '微信'],
        [2, '支付宝'],
        [3, '现金'],
        [4, '银联'],
      ]),
      formCopy: {},
      form: {
        amount: 0,
        payType: 1,
        remarks: '',
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入',
          },
        ],
        feeId: [
          {
            required: true,
            message: '请选择会费类型'
          }
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getYearList();
    // 设置canvas尺寸
    // this.$refs.canvasElement.width = this.canvasWidth;
    // this.$refs.canvasElement.height = this.canvasHeight;
  },
  beforeDestroy() {
    // 关闭摄像头
    console.log('关闭摄像头111');
    if (this.videoStream) {
      console.log('关闭摄像头');
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
  },
  methods: {
    startCamera() {
      console.log('打开摄像头111');
      // 获取本地摄像头视频流
      // 获取媒体对象（摄像头）
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      // navigator.mediaDevices.getUserMedia({ video: true })
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            console.log('打开摄像头');
            this.$refs.videoElement.srcObject = stream;
            this.videoStream = stream;
            this.$refs.videoElement.play();
          })
          .catch((error) => {
            console.error('打开摄像头失败：', error);
          });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          { video: true },
          (stream) => {
            this.$refs.videoElement.srcObject = stream;
            this.videoStream = stream;
            this.$refs.videoElement.play();
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        this.$message.error('不支持摄像头调用');
      }
    },
    takePicture() {
      // 将视频流绘制到canvas上
      const that = this;
      const { videoElement, canvasElement } = this.$refs;
      const context = canvasElement.getContext('2d');
      context.drawImage(videoElement, 0, 0, this.canvasWidth, this.canvasHeight);

      // 将canvas转换为base64格式的图片数据
      // const imageData = canvasElement.toDataURL('image/png');

      canvasElement.toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'photo.jpg');
        formData.append('userName', that.currentMember.memberId);
        formData.append('phoneNumber', that.currentMember.mobile);
        formData.append('photoType', 'time_card');
        formData.append('index', 'one');
        // 上传文件
        axios.post('https://www.famous-rfid.com/api/savePhoto', formData, {
          headers: { 'Access-Control-Allow-Credentials': true, 'Content-Type': 'multipart/form-data' }
        })
          .then((res) => {
            console.log(res);
            // that.previewImageUrl = URL.createObjectURL(blob);
            if (res.status === 200) {
              that.previewImageUrl = res.data.data;
              console.log(that.previewImageUrl);
              memberUpdateTimePicture({
                memberId: that.currentMember.memberId,
                timePicture: that.previewImageUrl
              }).then((mRes) => {
                console.log(mRes);
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 'image/jpeg');
      // 发送base64格式的图片数据到服务器
      // axios.post('/upload', { image: imageData })
      //   .then(response => {
      //     this.previewImageUrl = response.data.url
      //   })
      //   .catch(error => {
      //     console.error('上传失败：', error)
      //   });
    },
    getYearList() {
      yearList().then((data) => {
        // console.log(data);
        data.forEach((item) => {
          item.key = item.yearCardId;
        });
        this.feeList = data;
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleChooseFeeRule(item) {
      // console.log(item);
      if (item.checked) {
        this.$set(item, 'checked', false);
        this.selectFeeList = [];
      } else {
        for (let i = 0; i < this.selectFeeList.length; i++) {
          this.$set(this.selectFeeList[i], 'checked', false);
        }
        // this.timesCardPersonRechargeList = [];
        this.selectFeeList = [];
        this.selectFeeList.push(item);
        this.$set(item, 'checked', true);
        // this.handleAfterTimesCardPerson(item);
      }
    },
    handleSearch(e) {
      this.currentMember = {};
      this.selectFeeList = [];
      const searchText = e.target.value;
      if (searchText.length < 8) {
        return;
      }

      this.$store.commit('setDisableLoading', true);
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
      });
    },

    // 创建充值订单
    handleSubmit() {
      if (this.selectFeeList != null && this.selectFeeList.length > 0) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.handleSave();
          } else {
            this.$message.error('请按要求填写');
            return false;
          }
        });
      } else {
        this.$message.error('请选择充值规则');
        return false;
      }
    },
    // 提交支付订单
    handleSave() {
      console.log(this.selectFeeList[0]);
      this.form.amount = this.selectFeeList[0].price;
      const params = {
        memberId: this.currentMember.memberId,
        goodsName: this.selectFeeList[0].yearCardName,
        amount: Number(this.form.amount),
        amountPay: Number(this.form.amount), // 应付金额
        mobile: this.currentMember.mobile,
        remarks: this.form.remarks,
      };
      console.log(params);
      yearRechargeSubmit(params)
        .then((data) => {
          this.formCopy = deepClone(this.form);
          this.form = data;
          console.log(this.form);
          this.handlePay();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 收款
    handlePay() {
      this.payVisible = true;
    },
    // 支付成功
    handlePayOk() {
      if (this.paramsId) {
        this.getData(this.paramsId);
      } else {
        this.handleBack();
      }
    },
    // 取消支付
    handleCancel() {
      if (!this.paramsId) {
        this.form = this.formCopy;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}

.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.couponCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 200px;
  margin-right: 20px;
  height: 80px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid #ae6fda;
  border-radius: 10px;
  margin-bottom: 10px;
  .bbb {
    background: #ae6fda;
  }
  .left {
    border-radius: 7px 0 0 7px;
    padding-right:8px;
    background: #ae6fda;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .Title_name {
      font-size: 16px;
      line-height: 54px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.type-5 {
  border: 2px solid #409dff;
  .left {
    background: #ffd306;
  }
  .bbb {
    background: #ffd306;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}

.goods__list {
  display: flex;
  flex-flow: row wrap;
  max-height: 358px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.goods__item {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  .goods__item__tips {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    background: rgba(24, 144, 255, 0.5);
  }

  &:hover {
    .goods__item__tips {
      display: block;
    }
  }
}

.goods__table {
  margin-top: 10px;
  /*border: 1px solid red;*/
  /*max-height: 260px;*/
}

.goods__item--title,
.goods__item--text {
  width: 74px;
  word-break: break-all;
  font-size: 12px;
  margin: 5px 0;
  user-select: none;

  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.goods__item--title {
  font-size: 18px;
}
</style>
